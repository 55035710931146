import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <section
      className="section"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h1
        style={{ marginBottom: 16, marginTop: 16, alignSelf: "center" }}
        className="has-text-weight-bold is-size-1"
      >
        Not Found
      </h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </section>
  </Layout>
);

export default NotFoundPage;
